import React from 'react';
import styled from 'styled-components';
import { pageMargins } from '../shared/styles.js';

const Div = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${pageMargins}
`;

export default function Container({ children }) {
  return (<Div>{children}</Div>);
}
import React from 'react';
import Swiper from './Swiper';
import styled from 'styled-components';
import { pageMargins } from '../../shared/styles.js';

const StyledImageCarousel = styled.div`
  width: ${({ size }) => (size === 'l' ? '100%' : '45%')};
  ${({ size, page }) => (size === 'l' && !page ? pageMargins : '')}
  margin-top: ${({ size }) => (size === 'l' ? '0' : '0')};
  & .swiper-slide {
    img {
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      max-height: 48rem;
    }
  }
  & .swiper-slide--portrait {

      img {
        object-fit: contain;
        object-position: left center;
      }
    }
  }
  & .navigation-container {
    display: flex;
    width: 7rem;
    justify-content: space-between;
    margin-top: 1rem;
    & .swiper-next,
    .swiper-prev {
      transition: opacity 0.2s ease;
      margin-top: 2rem;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: ${({ page, size }) => (size === 'l' && !!page ? '0' : '0')};
    & .swiper-slide {
      img {
        width: 100%;
        height: ${({ size }) => (size === 's' ? '69vw' : 'initial')};
        max-height: 30rem;
      }
    }
  }
`;
const ImageCarousel = ({ images, size, captions, page, name }) => {
  return images.length > 0 ? (
    <StyledImageCarousel size={size} page={page}>
      <Swiper name={name} images={images} captions={captions} width={size === 'l' ? '2048' : '1024'} />
    </StyledImageCarousel>
  ) : null;
};

export default ImageCarousel;

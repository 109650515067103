import { Link } from 'gatsby';
import styled, {css} from 'styled-components';
import { typography, color } from '../shared/styles.js';

const baseStyle = css`
  border: ${({ borderColor }) => `1px solid ${borderColor ? borderColor : 'white'}`};
  min-width: ${({ svg }) => (svg ? '17.875rem' : '12.5rem')};
  height: 3.625rem;
  padding: 0 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: ${({ svg }) => (svg ? 'space-between' : 'center')};
  color: ${({ borderColor }) => `${borderColor ? borderColor : 'white'}`};
  font-size: ${typography.size.s1};
  font-weight: ${typography.weight.bold};
  font-family: ${typography.type.secondary};
  text-transform: uppercase;
  background: transparent;
  outline: none;
  text-decoration: none;
  letter-spacing: 0.1rem;
  z-index: 10;

  & svg {
    width: 1.15rem;
    margin-right: 0.3rem;
    margin-left: 1rem;
    transition: transform 0.3s ease;

    & * {
      fill: ${({ borderColor }) => `${borderColor ? borderColor : 'white'}`};
    }
  }

  &:hover {

    & svg {
      transform: translateX(5px);
    }
  }
  &:active,
  &:active:hover {
    color: ${({ activeColor }) => `${activeColor ? activeColor : color.darker}`};
    border-color: ${({ activeColor }) => `${activeColor ? activeColor : color.darker}`};

    & svg {
      fill: ${({ activeColor }) => `${activeColor ? activeColor : color.darker}`};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: ${color.grey};
    border-color: ${color.grey};

    & svg * {
      fill: ${color.grey};
    }

    &:hover {

      & svg {
        transform: translateX(0);
        fill: ${color.grey};
      }
    }
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
    min-width: unset;
    font-size: 0.875rem;
  }
  @media only screen and (min-width: 640px) {
    width: auto;
  }
`;

const StyledButton = styled.button`
  ${baseStyle}
`;

const StyledLink = styled(Link)`
  ${baseStyle}

  @media only screen and (min-width: 640px) {
    display: inline-flex;
  }
`;

export default StyledButton;
export {StyledLink};
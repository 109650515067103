import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import ArrowGallery from '../../images/svgs/ArrowGallery';

const SwiperContainer = ({ images, captions, number, name, width }) => {
  const swiperClass = name ? name.substr(0, 5) : '';
  const carousel = useRef(null);
  console.log('swi', swiperClass);
  const initSwiper = () => {
    carousel.current = new Swiper(
      name ? `.swiper-container__${swiperClass}` : '.swiper-container',
      {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 50,
        centeredSlides: true,
      },
    );
    const prev = document.querySelector(`.swiper-prev__${swiperClass}`);
    const next = document.querySelector(`.swiper-next__${swiperClass}`);
    const checkNavOpacity = () => {
      if (carousel.current.realIndex === 0) {
        prev.style.opacity = 0.17;
        next.style.opacity = 1;
      } else if (carousel.current.realIndex === images.length - 1) {
        prev.style.opacity = 1;
        next.style.opacity = 0.17;
      } else {
        prev.style.opacity = 1;
        next.style.opacity = 1;
      }
    };

    carousel.current.on('transitionEnd', function () {
      checkNavOpacity();
    });

    prev.addEventListener('click', () => carousel.current.slidePrev());
    next.addEventListener('click', () => carousel.current.slideNext());
    checkNavOpacity();
  };
  useEffect(() => {
    if (images.length > 1) {
      initSwiper();
    }
  }, []);

  return (
    <div
      key={number}
      className={`${name ? `swiper-container__${swiperClass}` : ''} swiper-container`}
    >
      <div className="swiper-wrapper">
        {images.map((image, i) => (
          <div className={`swiper-slide ${image.dimensions && (image.dimensions.width/image.dimensions.height < 1) ? 'swiper-slide--portrait' : ''}`} key={i}>
            <img src={`${image.url}&w=${width ?? 1024}`} alt="project image slide" />
            {captions ? <p>{captions[i]}</p> : ''}
          </div>
        ))}
      </div>
      {images.length > 1 ? (
        <div className="navigation-container">
          <div
            className={`swiper-prev__${swiperClass}`}
            onClick={carousel.current ? carousel.current.slidePrev() : null}
          >
            <ArrowGallery direction="left" />
          </div>
          <div className={`swiper-next__${swiperClass}`}>
            <ArrowGallery direction="right" />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SwiperContainer;
